.Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
  }
  
  .Table__pagination {
    display: flex;
    justify-content: flex-end;
    /* padding-top: 20px; */
    /*margin-top: 30px; */
    background-color: #f5f9ff;
  }
  
  .Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
  }
  
  .Table__pageButton--active {
    color: #1b3451;
    font-weight: bold;
  }
  
  .ReactTable .rt-thead.-header {
    box-shadow: none;
    background: #f5f5fd;
  }
  .ReactTable .rt-tbody .rt-td {
    border-right: 1px solid rgba(0, 0, 0, 0.02);
    border: none;
  }
  
  
  .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    border: none;
  
  }
  .ReactTable .rt-tr{
    margin-left: 10px;
    margin-right: 10px;
  }
  .ReactTable .rt-thead .rt-resizable-header-content {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  
  .ReactTable .rt-table {
    flex: auto 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
    border: 1px solid #d1d3d7;    
  }
  
  .ReactTable {
    position: relative;
    display: flex;
    flex-direction: column;
    border: none;    
  }
  
.csldatecontainer {
	border: 1px solid #DBDBDB;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    padding: 5px 5% 5px 5%;
    box-sizing: border-box;
}
.react-date-picker__wrapper {
	display: block;
    width: 106%;
}
.react-date-picker__inputGroup {
	padding-top: 7px;
    width: 70%;
    flex-grow: unset;
    display: inline-block;
}
.react-date-picker__button {
	display: inline-block;
	float: right;
}